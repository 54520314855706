* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
:root {
  --title-color: #464270;
  --white-color: #f7f6fb;
  --background-color: #ecf6fd;
  --Backgound-color: #ecf6fd !important;
  --primary: #1b5e20;
  --border-color: #add8e6;
  --first-color-light: #afa5d9;
  --white: #fff;
  --lbl-color: #3f4553;
  --btn-border: #cdd9ed;
  --box-shadow: 0px 0px 8px 0px #02025044;
  --font-Poppins: "Poppins", sans-serif;
  --font-Arial: Arial, sans-serif;
}
html,
body {
  height: 100%;
}
body {
  font-family: var(--font-Poppins);
  height: 100vh;
  overflow: hidden;
}
.jb_app {
  display: grid;
  grid-template-columns: 220px 1fr;
  grid-template-rows: 50px 1fr;
  grid-template-areas:
    "left-menu top-menu"
    "left-menu body";
  height: 100vh; /* Full viewport height */
  width: 100%;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  cursor: pointer;
}
::-webkit-scrollbar-track {
  background: white;
  cursor: pointer;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: silver;
  border-radius: 10px;
  cursor: pointer;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
body::-webkit-scrollbar {
  display: none;
}

/* =================================Page Header================================== */
header {
  grid-area: top-menu;
  background-color: #4caf50;
  padding: 10px;
  color: white;
  text-align: center;
  transition: all 0.5s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
}
header h1 {
  font-size: 20px;
  color: var(--primary);
  font-weight: bold;
}

.header-icon {
  position: relative;
  display: flex;
}
.hicon {
  position: relative;
  display: inline-block;
  margin-right: 20px;
  height: 30px;
  width: 30px;
  color: var(--lbl-color);
  border: 1px solid var(--btn-border);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in;
}
.hicon:hover {
  background-color: var(--primary);
  color: var(--white-color);
  border: 1px solid var(--primary);
}
.tooltip {
  visibility: hidden;
  font-size: 12px;
  background-color: black;
  color: #fff;
  text-align: center;
  width: 100px;
  padding: 3px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 3;
  bottom: -30px; /* Position the tooltip below the icon */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}
.hicon:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

/* =================================Menu Bar================================== */
nav {
  position: relative;
  grid-area: left-menu;
  padding: 0;
  transition: left 0.5s ease;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  z-index: 1;
}
nav::-webkit-scrollbar {
  display: none;
}
.nav-header {
  position: relative;
  width: 100%;
  height: 50px;
  transition: left 0.4s ease-in-out;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
  background-color: var(--white-color);
  border-bottom: 1px solid var(--primary);
  border-right: 1px solid var(--primary);
}
.nav-header h3 {
  font-size: 16px;
  color: var(--primary);
  text-align: left;
  font-weight: bold;
}
.btn-bars {
  display: none;
  position: absolute;
  top: 10px;
  right: 0;
  border-radius: 3px;
  transition: all 0.4s ease-in-out;
  border: 1px solid var(--primary);
  padding: 5px 10px;
  font-size: 13px;
  cursor: pointer;
  z-index: 999;
}
.btn-bars:hover span {
  background-color: var(--primary);
  color: var(--white);
}
.btn-bars.click span {
  left: 225px;
}
.btn-bars.click span {
  background-color: var(--primary);
  color: var(--white);
}
.btn-bars.click span:before {
  content: "\f00d";
}
nav ul {
  height: 100%;
  width: 100%;
  list-style: none;
  padding: 0;
  overflow: auto;
  background-color: var(--primary);
}
.nav-item {
  list-style: none;
  width: 100%;
  display: grid;
  grid-template-columns: auto;
  text-align: left;
  border-bottom: 1px solid #b9f6ca26;
}
.nav-item span {
  position: relative;
  width: 100%;
  text-decoration: none;
  font-size: 15px;
  height: 50px;
  color: var(--first-color-light);
  padding: 0 10px;
  display: grid;
  grid-template-columns: 30px auto 30px;
  align-items: center;
  text-align: left;
  cursor: pointer;
  transition: all 0.3s ease;
}
.nav-item span:hover,
.nav-item.active span {
  color: var(--white);
  border-left: 3px solid var(--white);
  border-radius: 5px 0 0 5px;
  padding-left: 15px;
}

/* =================================Main Header================================== */
main {
  position: relative;
  grid-area: body;
  padding: 0;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  overflow: hidden;
}
label {
  font-size: 12px;
  font-weight: 500;
  color: #3f4553;
}
input {
  outline: none;
}
.form-control {
  width: 100%;
  padding-left: 10px;
  font-size: 12px;
  height: 32px;
  color: black;
  border-radius: 5px;
  border: 1px solid var(--border-color);
}
.form-control:focus {
  border: 1px solid rgba(81, 203, 238, 1);
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}
div:focus,
section:focus {
  outline: none !important;
}
::placeholder {
  font-size: 12px;
}
/* ================================= Customized Select Option Style ===================================== */
.custom-select {
  width: 100%;
  display: grid;
  grid-template-columns: auto;
  padding: 0;
  cursor: pointer;
  position: relative;
  height: auto;
  border: none;
  gap: 0;
}
.custom-select .fa-caret-down {
  position: absolute;
  color: #3f4553;
  font-size: 12px;
  right: 10px;
  top: 10px;
}
.custom-option {
  position: absolute;
  top: 32px;
  z-index: 1003;
  width: 100%;
  display: grid;
  grid-template-columns: auto;
  border-radius: 0 0 5px 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  height: auto;
  cursor: pointer;
  background: #fff;
  gap: 0;
}
.search-option {
  position: sticky;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  padding: 3px;
  border: none;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background: #fff;
}
.search-option input {
  margin: 0;
  min-height: 35px;
  height: 35px;
  width: 100%;
  border: 1px solid var(--btn-border);
  padding: 0 0 0 10px;
  color: black;
  font-weight: 500;
  border-radius: 5px;
}
.search-option input:focus {
  border: 1px solid var(--dark-blue);
}
.search-option span {
  color: gray;
  font-weight: 400;
  opacity: 0.9;
  text-transform: capitalize;
  width: 100%;
  font-size: 12px;
  text-align: left;
  padding: 5px;
  height: 100%;
}
.custom-option .option-list {
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  max-height: 200px;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: auto;
  border-radius: 0 0 5px 5px;
  padding: 0 5px;
}
.custom-option .option-list button {
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 10px 10px;
  background: none;
  border: none;
  outline: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: wrap;
}
.custom-option .option-list button:hover {
  color: var(--white-color);
  border-radius: 5px;
  background-color: var(--primary); /* Darker blue on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}
.custom-option .option-list button:hover label {
  color: var(--white-color);
}
.custom-option .option-list button:focus {
  color: var(--white-color);
  border-radius: 5px;
  background-color: var(--primary); /* Darker blue on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}
.custom-option .option-list button:focus label {
  color: var(--white-color);
}
.custom-option .option-list button:last-child {
  margin-bottom: 5px;
}
.custom-option .option-list .pro-con {
  width: 100%;
  background: none;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  text-align: left;
  align-items: flex-start;
  border-bottom: 1px solid #add8e6;
  cursor: pointer;
  padding: 5px;
  transition: all 0.3s ease;
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
}
.custom-option .option-list .pro-con .sub-con {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.custom-option .option-list .pro-con .sub-con label {
  color: black;
  font-weight: 600;
}
.custom-option .option-list .pro-con .sub-con:nth-child(2) {
  align-items: flex-end;
}
.custom-option .option-list .pro-con:hover label {
  color: var(--white-color) !important;
}
.custom-option .option-list .pro-con:focus {
  border-radius: 5px;
  background-color: var(--primary); /* Darker blue on hover */
}
.custom-option .option-list .pro-con:focus label {
  color: var(--white-color);
}
.custom-option .option-list button:hover label {
  color: var(--white-color);
}
.card-wrapper {
  padding: 10px;
  padding-bottom: 100px;
  height: 100vh;
  background: #fff;
  overflow: auto;
}
.table-admin {
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
  cursor: pointer;
  height: 100%;
  overflow: auto;
}
.table-admin .option-list {
  position: relative;
  width: 100% !important;
  padding: 0;
  margin: 0;
  border-collapse: collapse;
}
.table-admin table thead {
  top: -1px;
  margin: 0;
  padding: 0;
}
.table-admin table thead tr {
  margin: 0;
  padding: 0;
}
.table-admin td,
.table-admin th {
  border: 0;
  border-bottom: 0.5px solid #ddd;
  border-right: 0.5px solid #ddd;
  border-radius: 0 !important;
}
.table-admin th {
  text-align: center;
  border-top: 1px solid #ddd !important;
  background: #f2f2f2 !important;
  font-weight: bold !important;
  padding: 4px;
  color: var(--lbl-color);
  font-size: 12px;
}
.table-admin td {
  font-weight: 500;
  padding: 7px 5px;
  font-size: 11px !important;
  word-wrap: break-word;
  word-break: break-all;
}
.table-admin tr td:first-child,
.table-admin tr th:first-child {
  border-left: 1px solid #ddd !important;
}
.table-admin tr:hover,
.table-admin tr:hover td {
  background: none;
}
.table-admin tr:focus {
  outline: none;
}
.btn-section {
  position: sticky;
  bottom: 0;
  left: 0;
  padding: 5px 0;
  margin: 0;
  width: 100%;
  padding-right: 30px;
  row-gap: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 5px;
  z-index: 1;
  height: auto;
  min-height: 50px;
  background-color: #f9f9fb;
  border-top: 1px solid #ddd;
}
.btn-fabgreen {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  font-size: 14px;
  padding: 5px 10px;
  border: none;
  outline: none;
  border-radius: 5px;
  color: #3f4553 !important;
  border: 1px solid var(--btn-border) !important;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out !important;
}
.btn-fabgreen:hover:not(:disabled),
.btn-fabgreen:focus:not(:disabled) {
  color: var(--white-color) !important;
  background-color: var(--primary); /* Darker blue on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}
.btn-fabgreen:disabled {
  border: lightpink;
  color: #3f4553;
  background-color: lightpink; /* Darker blue on hover */
}

.Script_Container h5 {
  font-weight: 700;
  display: flex;
  justify-content: center;
}
.Script_Note {
  margin-top: 10px;
  line-height: 10px;
}
.Script_Note label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: red;
}
.Script_Note p {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  column-gap: 10px;
  font-size: 13px;
  font-weight: 400;
}
.Script_Note p span {
  font-size: 20px;
}
.JB_UserTab {
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: auto;
  column-gap: 10px;
  border-radius: 3px;
}
.JB_UserTab button {
  padding: 0 10px;
  outline: none;
  border: none;
  font-size: 11px;
  width: 100px;
  height: 30px;
  white-space: nowrap;
  text-transform: uppercase;

  font-weight: 700;
  color: black;
  background: none;
  position: relative;
}
.JB_UserTab button span {
  display: none;
}
.JB_UserTab .Show_Tab {
  position: relative;
}
.JB_UserTab .Show_Tab span {
  display: block;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  transition: all ease-in-out 0.5s;
  height: 4px;
  background: blue;
  border-radius: 1px;
  opacity: 0.5;
}
.Cld-Backup {
  display: flex;
  justify-content: space-between;
  height: 40px;
  border-bottom: 2px solid rgb(222, 242, 250);
  margin-bottom: 20px;
}
.Cld-Backup label {
  font-size: 20px;
  text-transform: capitalize;
  color: #464270;
  font-weight: 700;
}
.btn-Cld-Backup {
  border: 2px solid darkgreen;
  border-radius: 5px;
  height: 35px;
  min-width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: darkgreen;
  transition: 0.5s ease all;
  background-color: #fff;
  white-space: nowrap;
  font-weight: 700;
}
.btn-Cld-Backup:hover {
  background-color: darkgreen;
  border: 2px solid darkgreen;
  color: #fff;
}
.DBTable {
  max-height: 300px;
  overflow: auto;
}
.avldb:first-child {
  padding: 0 0 0 10px;
}
.avldb:last-child {
  padding: 0 10px 0 0;
}
/* ----------------------------------------- Restore Database Style ---------------------------------------- */
.restorcon {
  margin-top: 20px;
  width: 100%;
  text-align: center;
  font-size: 20px;
  color: #3f4553;
}
.resprogress {
  margin-top: 10px;
  position: relative;
  width: 100%;
  height: 100%;
}
.steps {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 9px;
  color: #99a2a8;
  font: 500 13px/1 "Roboto", sans-serif;
}
.step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  row-gap: 10px;
}
.step span {
  height: 30px;
  width: 30px;
  background: #eaf0f4;
  color: #99a2a8;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  z-index: 3;
  cursor: pointer;
}
.step.completed {
  color: #5cb85c;
  font: normal normal normal 30px/50px;
}
.step.completed span {
  background: #5cb85c;
  color: #fff;
}
#percentpen {
  position: absolute;
  top: 10px;
  left: 30px;
  height: 10px;
  width: 90%;
  background-color: #eaf0f4;
  transition: all 0.35s ease-in;
  z-index: 1;
}
#percentcomp {
  position: absolute;
  top: 10px;
  left: 30px;
  height: 10px;
  background-color: #5cb85c;
  transition: all 0.35s ease-in;
  z-index: 2;
}
.custom-file {
  width: 100%;
}
.submitbtn {
  outline: none;
  height: auto;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
  font: 700 14px/40px "Roboto", sans-serif;
  border: 1px solid #99a2a8;
  text-align: center;
  transition: all 300ms linear 0s;
  color: #5f6771;
  background: transparent;
}
.submitbtn i {
  font-size: 20px;
  padding-right: 10px;
}
.submitbtn:hover,
.submitbtn:focus {
  background: #5cb85c;
  border-color: #5cb85c;
  color: #fff;
}
.custom-file .filebtn {
  outline: none;
  width: 100% !important;
  border-radius: 5px;
  cursor: pointer;
  font: 700 14px/40px "Roboto", sans-serif;
  border: 1px solid #99a2a8;
  text-align: left;
  transition: all 300ms linear 0s;
  color: #5f6771;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-file .filebtn i {
  font-size: 20px;
  padding-right: 10px;
}
.custom-file .filebtn:hover,
.custom-file .filebtn:focus {
  background: var(--primary);
  border-color: var(--primary);
  color: var(--white-color);
}
.custom-file .custom-file input {
  display: none !important;
}
.file_added {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.file_added li {
  list-style: none;
}
.file_added a {
  font: 400 15px/28px "Roboto", sans-serif;
  color: #5cb85c;
  font-weight: 500;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
}
.file_added i {
  font-size: 14px;
  margin: 0 5px;
}
.file_added i:first-child {
  transform: rotate(-35deg);
}
.file_added i:last-child:hover {
  color: #5f6771;
  transform: scale(1.1);
}
.file_added a span:hover {
  text-decoration: underline;
}

/* ===================================================Alert Message ========================================== */

.alertMsg {
  background: darkgreen;
  padding: 10px 15px;
  width: auto;
  right: 10px;
  top: 10%;
  position: absolute;
  border-radius: 4px;
  border-left: 5px solid #97bc62ff;
  border-right: 5px solid #97bc62ff;
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  align-items: center;
  text-align: left;
  color: white;
  z-index: 9999;
}
.alertMsg .msg {
  font-size: 12px;
  max-width: 300px;
  word-break: break-all;
  color: white;
}
.alertMsg .msg::-webkit-scrollbar {
  display: none;
}
.alertMsg .close-btn {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  cursor: pointer;
  color: white;
  font-size: 16px;
}
.alertMsg .close-btn:hover {
  background: white;
  border-radius: 5px;
  color: darkgreen;
  font-weight: bold;
}
.alertMsg.Show {
  animation: Show_slide 1s ease forwards;
}
.alertMsg.hide {
  display: none;
}
.alertMsg.hide {
  animation: hide_slide 1s ease forwards;
}
@keyframes Show_slide {
  0% {
    transform: translateX(100%);
  }

  40% {
    transform: translateX(-10%);
  }

  80% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-10px);
  }
}
@keyframes hide_slide {
  0% {
    transform: translateX(-10px);
  }

  40% {
    transform: translateX(0%);
  }

  80% {
    transform: translateX(-10%);
  }

  100% {
    transform: translateX(100%);
  }
}
/* =========================================== Dialog Message Alert Style ======================= */
.Msg-Popup {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.PopupClose {
  display: none;
}
.Msg-Container {
  height: 150px;
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 7px;
  background-color: white;
  box-shadow: 0 5px 5px 0 lightblue;
}
.Msg-Header {
  height: 35px;
  background: rgb(250, 251, 250);
  font-size: 25px;
  display: flex;
  justify-content: flex-start;
  padding: 0 10px;
  border-radius: 10px;
  border-bottom: 1px solid rgb(230, 226, 226);
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Msg-Header i {
  cursor: pointer;
  color: darkgreen;
}
.Msg-Header .MsgTooltip {
  display: none;
}
.Msg-Header .MsgClose {
  display: none;
}
.Msg-Header .MsgTooltip.active {
  display: block;
  z-index: 1;
  position: absolute;
  padding-top: 20px;
  margin-left: 30px;
  font-size: 12px;
  text-transform: capitalize;
  color: #464270;
  border-radius: 7px;
  padding: 3px;
}
.Msg-Header .MsgClose.Close {
  position: absolute;
  z-index: 1;
  text-transform: capitalize;
  color: #464270;
  border-radius: 50%;
  font-size: 25px;
}
.Msg-Header i:nth-child(2):hover {
  color: #fff;
  border-radius: 50%;
  background-color: darkgreen;
}
.Msg-Body {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
  color: #464270;
  padding: 0 5px;
  white-space: wrap;
}
.Msg-Footer {
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: 20px;
  align-items: center;
  height: 30px;
  padding: 0 10px;
}
.Msg-Footer button {
  width: 100px;
  font-size: 15px;
  height: 35px;
  border: none;
  border-radius: 5px;
  background-color: white;
  color: #464270;
  display: flex;
  justify-content: center;
  column-gap: 5px;
  align-items: center;
  box-shadow: none;
  font-weight: 700;
}
.Msg-Footer button:hover {
  background: darkgreen;
  color: #fff;
  border: none;
  outline: none;
}
.Msg-Footer button:focus {
  background: darkgreen;
  color: #fff;
  border: none;
  outline: none;
}

/*======================LoadingStyle Design=======================*/
.Loading-Popup {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10000;
  position: fixed;
  background: #00000050;
}
.Loading-Design {
  width: 200px;
  height: 60px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.circle {
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  background-color: #fff;
  left: 15%;
  transform-origin: 50%;
  animation: circle 0.5s alternate infinite ease;
}
@keyframes circle {
  0% {
    top: 60px;
    height: 5px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }
  40% {
    height: 20px;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0%;
  }
}
.circle:nth-child(2) {
  left: 45%;
  animation-delay: 0.2s;
}
.circle:nth-child(3) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}
.shadow {
  width: 20px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 62px;
  transform-origin: 50%;
  z-index: -1;
  left: 15%;
  filter: blur(1px);
  animation: shadow 0.5s alternate infinite ease;
}
@keyframes shadow {
  0% {
    transform: scaleX(1.5);
  }
  40% {
    transform: scaleX(1);
    opacity: 0.7;
  }
  100% {
    transform: scaleX(0.2);
    opacity: 0.4;
  }
}
.shadow:nth-child(4) {
  left: 45%;
  animation-delay: 0.2s;
}
.shadow:nth-child(5) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}
.Loading-Design span {
  position: absolute;
  top: 75px;
  font-size: 20px;
  letter-spacing: 12px;
  color: #fff;
  left: 15%;
}
.Loading {
  position: fixed;
  z-index: 1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
}
.Loading-Background {
  height: 50px;
  width: 200px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
}
.Loading span {
  font-size: 20px;
  padding-left: 10px;
  color: black;
  font-weight: bold;
}
.Loading-Circle {
  border: 5px solid white;
  border-radius: 50%;
  border-top: 5px solid darkgreen;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* =======Loading Animation ===========*/
#panel {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: white;
  z-index: 1;
}
#panel:hover {
  border-color: #444;
}
#loading8-1,
#loading8-2,
#loading8-3,
#loading8-4,
#loading8-5,
#loading8-6 {
  display: block;
  position: absolute;
  margin: 0;
  width: 5px;
  height: 5px;
}
#loading8-1 #CircleBottom,
#loading8-2 #CircleMiddle,
#loading8-3 #CircleTop,
#loading8-4 #CircleRight,
#loading8-5 #CircleRight1,
#loading8-6 #CircleRight2 {
  display: block;
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
}
#loading8-1 #CircleBottom #ball,
#loading8-2 #CircleMiddle #ball,
#loading8-3 #CircleTop #ball,
#loading8-4 #CircleRight #ball,
#loading8-5 #CircleRight1 #ball,
#loading8-6 #CircleRight2 #ball {
  width: 7px;
  height: 7px;
  display: block;
  position: absolute;
  background-color: #06c;
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  margin: 0 0 0 20px;
}
#loading8-1 #CircleBottom {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}
#loading8-2 #CircleMiddle {
  -webkit-transform: rotate(50deg);
  -moz-transform: rotate(50deg);
  -o-transform: rotate(50deg);
  -ms-transform: rotate(50deg);
  transform: rotate(50deg);
}
#loading8-3 #CircleTop {
  -webkit-transform: rotate(100deg);
  -moz-transform: rotate(100deg);
  -o-transform: rotate(100deg);
  -ms-transform: rotate(100deg);
  transform: rotate(100deg);
}
#loading8-4 #CircleRight {
  -webkit-transform: rotate(150deg);
  -moz-transform: rotate(150deg);
  -o-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}
#loading8-5 #CircleRight1 {
  -webkit-transform: rotate(200deg);
  -moz-transform: rotate(200deg);
  -o-transform: rotate(200deg);
  -ms-transform: rotate(200deg);
  transform: rotate(200deg);
}
#loading8-6 #CircleRight2 {
  -webkit-transform: rotate(250deg);
  -moz-transform: rotate(250deg);
  -o-transform: rotate(250deg);
  -ms-transform: rotate(250deg);
  transform: rotate(250deg);
}
#loading8-1,
#loading8-2,
#loading8-3,
#loading8-4,
#loading8-5,
#loading8-6 {
  -webkit-animation: cwSpin 1.3s linear 0.3s infinite;
  -moz-animation: cwSpin 1.3s linear 0.3s infinite;
  -o-animation: cwSpin 1.3s linear 0.3s infinite;
  -ms-animation: cwSpin 1.3s linear 0.3s infinite;
  animation: cwSpin 1.3s linear 0.3s infinite;
}
@-webkit-keyframes cwSpin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes cwSpin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-ms-keyframes cwSpin {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
  }
}
@-o-keyframes cwSpin {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
  }
}
@keyframes cwSpin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* =============================OTP Validatio  =========================== */
.title {
  max-width: 400px;
  margin: auto;
  text-align: center;
  h3 {
    font-weight: bold;
  }
  p {
    font-size: 12px;
    color: #118a44;
    &.msg {
      color: initial;
      text-align: initial;
      font-weight: bold;
    }
  }
}
.otp-input-fields {
  margin: auto;
  background-color: white;
  box-shadow: 0px 0px 8px 0px #02025044;
  max-width: 400px;
  width: auto;
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 40px;
  border-radius: 10px;
}

.otp_digit {
  height: 40px;
  width: 40px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #2f8f1f;
  text-align: center;
  outline: none;
  font-size: 16px;
}
/* ------------------------------------------------POS Popup Screen --------------------------------------- */
.popup-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999 !important;
  background-image: url("https://www.transparenttextures.com/patterns/lyonnette.png");
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-content {
  position: relative;
  max-width: 70%;
  min-width: 40%;
  margin: auto;
  padding: 0;
  border-radius: 7px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
.popup-content.auto {
  width: auto;
  max-width: 80%;
}
.popup-content.sub {
  max-width: 30%;
  min-width: 30%;
}
.popup-content.medium {
  min-width: 50%;
}
.popup-header {
  height: 50px;
  width: 100%;
  padding: 5px;
  background-color: #f9f9fb;
  border-bottom: 1px solid #ddd;
  border-radius: 7px 7px 0 0;
  display: flex;
  align-items: center;
}
.popup-header h5 {
  margin: 0;
  width: 100%;
  color: black;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 700;
  padding: 5px 0 0 10px;
}
.popup-body {
  width: 100%;
  height: 100%;
  padding: 0;
  min-height: 50vh;
  max-height: 50vh;
  overflow: auto;
}
.popup-card {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 60vh;
  height: auto;
  max-height: 60vh;
  padding: 0;
  border-right: 1px solid var(--btn-border);
  overflow: auto;
}
.popup-card .custom-select {
  position: relative;
  margin-top: 5px;
  padding: 0 5px;
}
.popup-card .m-hsearch {
  width: 100%;
  margin: 5px 0 0 0;
  padding: 0 5px;
  background: #fff;
}
.popup-card .m-hsearch input {
  height: 32px !important;
}
.data-list {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.data-list button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding: 5px 10px;
  outline: none;
  border: none;
  border-bottom: 0.5px solid #add8e6;
  transition: all 0.3s ease;
  background: transparent;
  word-wrap: break-word;
  word-break: break-all;
}
.data-list button label {
  color: black;
  font-weight: 600;
  font-size: 12px;
  width: 100%;
  padding: 0;
  margin: 0;
}
.data-list button img {
  height: 30px;
  width: 30px;
  border-radius: 5px;
  background: white;
  border: 1px solid #add8e6;
  margin-right: 5px;
}
.data-list button i {
  color: darkgreen;
  opacity: 0.8;
  font-size: 12px;
  transition: all 0.3s ease;
}
.data-list button:hover,
.data-list button:focus {
  border-radius: 5px;
}
.data-list button:hover label,
.data-list button:focus label {
  color: var(--primary);
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}
.data-list button i:hover {
  color: blue;
  font-weight: 600;
}
.popup-card .data-list {
  position: relative;
  margin-top: 5px;
}
.popup-body .row {
  margin: 0;
  padding: 0;
}
.popup-body .search-card {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 70% 30%;
  gap: 10px;
  padding: 5px 15px;
}
.popup-body .search-card input {
  width: 100% !important;
}
.popup-body .search-card.full {
  width: 100%;
  display: grid;
  grid-template-columns: auto;
}
.popup-footer {
  min-height: 0;
  margin: 0;
  width: 100%;
  padding: 5px;
  background-color: #f9f9fb;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-top: 1px solid #ddd;
  border-radius: 0 0 7px 7px;
}
.popup-btn-group {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  column-gap: 10px;
  flex-wrap: wrap;
  row-gap: 10px;
  overflow: auto;
} /* -------------------------------------Icon Button and  TooolTip ------------------------------------ */
.icon-btn-grp {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  column-gap: 10px;
}
.icon-btn {
  position: relative;
  display: inline-block;
  padding: 3px 10px;
  text-align: center;
  border-radius: 10px;
  border: 1px solid #ddd;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  color: darkgreen;
  overflow: hidden;
  cursor: pointer;
}
.icon-btn i {
  transition: 0.2 linear;
  font-size: 12px;
}
.icon-btn button {
  background: transparent;
  border: none;
  outline: none;
  color: darkgreen;
}
.icon-btn:hover i,
.icon-btn:hover button {
  transform: scale(1.3);
  color: white;
}
.icon-btn::before {
  content: "";
  position: absolute;
  width: 120%;
  height: 120%;
  background: darkgreen;
  transform: rotate(45deg);
  left: -110%;
  top: 90%;
}
.icon-btn:hover::before {
  animation: aaa 0.7s 1;
  top: -10%;
  left: -10%;
}
.icon-btn img {
  height: 20px;
  width: 20px;
  line-height: 20px;
  transition: 0.2 linear;
  font-size: 15px;
}
.icon-btn:hover img {
  transform: scale(1.3);
}
.icon-popup-btn-grp {
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: -5px;
  transform: translate(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  text-align: center;
}
@keyframes aaa {
  0% {
    left: -110%;
    top: 90%;
  }
  50% {
    left: 10%;
    top: -30%;
  }
  100% {
    top: -10%;
    left: -10%;
  }
}
/* ---------------------------------   Login Page Style--------------------------- */

.login-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: first baseline;
}
.login-content {
  position: relative;
  color: black;
  background: #fff;
  padding: 0;
  border-radius: 15px;
  margin-top: 3%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.login-Img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
}
.login-Img img {
  width: 250px;
  height: 60px;
  object-fit: cover;
}
.login-header {
  display: block;
  line-height: 10px;
}
.login-header h6 {
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 500;
}
.login-header label {
  color: green;
  font-size: 15px;
  text-transform: capitalize;
}
.err-msg {
  width: 100%;
  max-width: 300px;
  word-wrap: wrap;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-align: center;
  color: red;
  font-size: 10px;
  font-weight: 500;
  animation: blinking 2.5s infinite;
}
.login-input {
  position: relative;
  padding: 0 10px;
}
.login-input label {
  font-weight: 400;
}
.login-usepass {
  display: flex;
  align-items: center;
  text-align: left;
  column-gap: 10px;
  padding: 2px 10px;
  border-radius: 8px;
  font-size: 12px;
  background: #f5f3f3;
  border-right: 3px solid lightgray;
  border-left: 3px solid lightgray;
  transition: all 0.5s ease-in-out;
}
.login-usepass input {
  min-width: 250px;
  height: 35px;
  padding: 0 10px;
  border: none;
  outline: none;
  background: #f5f3f3;
  color: #000000;
  font-weight: 600;
  font-size: 13px;
  border-left: 2px solid lightgray;
}
.login-usepass:hover {
  transform: scale(1.03);
}
.login-usepass input::placeholder {
  color: black;
  font-weight: 500;
}
.login-usepass i {
  font-size: 12px;
  color: green;
}
.login-btn {
  width: 100%;
  padding: 10px 10px 15px 10px;
}
.login-btn button {
  width: 80%;
  height: 40px;
  margin: auto;
  border: none;
  outline: none;
  background: green;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 7px;
  transition: all 0.5s ease-in-out;
}
.login-btn button:hover {
  background: var(--primary);
  color: var(--white-color);
  border: 1px solid yellowgreen;
}
.login_progress {
  position: absolute;
  left: 5px;
  bottom: 0;
  height: 7px;
  width: 97%;
  transform: scaleX(0);
  transform-origin: left;
  background-image: linear-gradient(to right, #539bdb, #3250bf);
  border-radius: 0 0 15px 15px;
  animation: load 0.5s 0.001s linear forwards;
}
.Autobackup {
  padding: 0 10px 10px 10px;
}
.Autobackup p {
  margin: 0;
  padding: 8px 0 0 0;
  font-size: 13px;
  color: black;
}
.aub-battom {
  margin-top: 10px !important;
  text-align: center;
}
@keyframes fade-in {
  5% {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes load {
  to {
    transform: scaleX(1);
  }
}
@keyframes blinking {
  0% {
    color: red;
  }
  47% {
    color: black;
  }
  62% {
    color: red;
  }
  97% {
    color: black;
  }
  100% {
    color: red;
  }
}
@keyframes fade-in {
  5% {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes load {
  to {
    transform: scaleX(1);
  }
}
@keyframes blinking {
  0% {
    color: red;
  }
  47% {
    color: black;
  }
  62% {
    color: red;
  }
  97% {
    color: black;
  }
  100% {
    color: red;
  }
} /* Hide the actual file input */
input[type="file"] {
  display: none;
}

/* Style the label as a custom button */
.custom-file-label {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--lbl-color);
  background-color: var(--background-color);
  border-radius: 5px;
  cursor: pointer;
  font-size: 13px;
  transition: all 0.3s ease-in;
}

/* Add hover effect for the custom button */
.custom-file-label:hover {
  padding-left: 20px;
  color: rgb(97, 167, 238);
}

@media (max-width: 900px) {
  body {
    height: 100%;
    overflow: auto;
  }
  .jb_app {
    grid-template-columns: 1fr;
    grid-template-rows: 50px 1fr;
    grid-template-areas:
      "top-menu"
      "body"
      "left-menu";
  }
  nav {
    position: absolute;
    top: 50px;
    left: -220px;
    display: none;
  }
  nav.show {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    left: 0;
  }
}
/* ------------------------------------------- React Table style------------------------------------------ */
.react-table-container {
  position: relative;
  border: none;
  padding: 0;
  margin: 0;
  font-weight: 500;
  border-radius: 10px;
  overflow: auto;
}
.react-table-container.autotable {
  max-width: 100%;
  overflow: auto;
}
.react-table {
  position: relative;
  background-color: white;
  padding: 0;
  margin: 0;
  border-collapse: collapse;
  max-width: 100%;
}
.react-table.full-table {
  width: 100%;
  overflow: auto;
  max-width: 100%;
}
.react-table.auto-table {
  width: auto;
  overflow: auto;
  max-width: 100%;
}
.rt-thead {
  position: sticky;
  top: -5px;
  background: var(--primary);
  z-index: 1;
}
.rt-tbody {
  background-color: white;
  overflow-x: hidden !important;
  border: 0.5px solid #ddd;
}
.rt-tr {
  position: relative;
  padding: 0;
  margin: 0;
}
.rt-th {
  border-right: 0.5px solid rgb(241, 246, 248) !important;
  color: var(--white-color);
  padding: 0;
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  height: 30px;
  white-space: nowrap !important;
}
.rt-td {
  cursor: pointer;
  font-size: 12px;
  position: relative;
  margin: 0;
  padding: 0 2px;
  border: 0 !important;
  height: 28px !important;
  max-height: 28px !important;
  border-bottom: 0.5px solid rgb(241, 246, 248) !important;
  border-right: 0.5px solid rgb(241, 246, 248) !important;
}
.flex-wrap1 {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  font-size: 12px;
  font-weight: 500;
}
/* ------------------------------------------------ HTML TABLE style-------------------------------------------- */
table {
  width: 100%;
  margin-bottom: 10px;
  position: relative;
}
th,
td {
  border: 1px solid rgb(241, 246, 248);
  padding: 3px;
  font-size: 12px;
  text-align: left;
  color: black;
}
th {
  text-align: center;
  white-space: nowrap;
  font-weight: 500;
  padding: 5px;
  background: var(--primary);
  color: var(--white-color);
}
thead {
  position: sticky;
  top: 0;
  z-index: 1;
}
tbody tr:hover {
  background-color: #f2f2f2;
}
tfoot td {
  text-align: right !important;
  font-weight: 600;
  background: #fff;
}
thead tr:first-child th:first-child {
  border-radius: 10px 0 0 0;
}
thead tr:first-child th:last-child {
  border-radius: 0 10px 0 0;
}
tfoot tr:first-child td:first-child {
  border-radius: 10px !important;
}
tfoot tr:first-child td:last-child {
  border-radius: 10px !important;
}

.danger {
  color: red;
}
